.bg-blue {
  background-color: #0a14e2;
}

.bg-light-blue {
  background-color: #485d94;
}

.bg-light-green {
  background-color: #7a968c;
}

.bg-yellow {
  background-color: #e1d5a4;
}

.tx-yellow,
.navbar-brand:hover {
  color: #e1d5a4;
}

.tx-dark-blue {
  color: #141c3e;
}

p {
  word-wrap: break-word;
}

.nav-link:hover {
  color: white;
}

.zoom {
  transition: transform 0.25s;
}

.zoom:hover {
  transform: scale(1.2);
}

.card-img-top {
  width: 100%;
  height: 22vw;
  object-fit: cover;
}

.fadeInLeft {
  -webkit-animation: fadeInLeft 1.2s both;
  -moz-animation: fadeInLeft 1.2s both;
  -o-animation: fadeInLeft 1.2s both;
  animation: fadeInLeft 1.2s both;
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* ---------- */

.fadeInRight {
  -webkit-animation: fadeInRight 1.2s both;
  -moz-animation: fadeInRight 1.2s both;
  -o-animation: fadeInRight 1.2s both;
  animation: fadeInRight 1.2s both;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(50px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(50px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/*  */

.collapsible {
  width: 100%;
  min-height: 90px;
  background: rgb(222, 214, 214);
  font-size: 28px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  border: none;
  border-radius: 20px;
}

.content-parent {
  height: 0px;
  overflow: hidden;
  transition: height ease 0.6s;
  width: 100%;
}
  
.content {
  font-size: 20px;
  min-height: 90px;
}
  
button > i {
  position: absolute;
  right: 1%;
}

/*  */

@media (max-width: 375px) { 

.logo { display: none; }  
  
}